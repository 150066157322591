@import './mixins.scss';

.root {
	@include buttonReset();
	display: flex;
	align-items: center;
	justify-content: center;
	width: 62px;
	height: 62px;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 32px;
}
.root__correct {
	background: var(--green);
}
.root__none {
	border: 2px solid var(--color-gray);
}
.root__absent {
	
	background: var(--color-gray);
}
.root__present {
	background: var(--yellow);
}
