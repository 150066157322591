@import './mixins.scss';

body {
	--color-background: #121213;
	--color-gray: #3a3a3c;
	--green: #538d4e;
	--real100vh: 100vh;
	--yellow: #b59f3b;

	background: var(--color-background);

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
}

.root {
	display: grid;
	padding: 16px;
	min-height: var(--real100vh);
	color: white;
	text-align: center;
	font-family: sans-serif;
	grid-template-rows: auto 1fr auto;
	gap: 16px;

	a {
		color: inherit;
		vertical-align: middle;
		font-size: 14px;
		justify-self: flex-end;
	}
}

.h1 {
	font-weight: bold;
	font-size: 30px;
}

.gridWrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.grid {
	display: grid;
	grid-template-columns: repeat(5, auto);
	gap: 5px;
}

.lastRowTitle {
	margin-top: 10px;
	grid-column: 1 / -1;
}

.next {
	@include buttonReset();
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 5px;
	width: 62px;
	height: 42px;
	border: 2px solid var(--color-gray);
	font-weight: bold;
	grid-column: 5;
}
.next__hide {
	display: none;
}

.footer {
	font-size: 14px;
}