@mixin buttonReset() {
	margin: 0;
	padding: 0;
	border: none;
	background: none;
	color: inherit;
	font-size: inherit;
	cursor: pointer;

	&:disabled {
		cursor: default;
	}
}
